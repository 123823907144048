$(document).ready(function() {
    FastClick.attach(document.body);
	
	$('.toggleSubmit').click(function(ev) {
        ev.preventDefault();
		if($(this).hasClass('checked')){
			$('#formSubmit').prop('disabled', true);
		}else{
			$('#formSubmit').prop('disabled', false);
		}
        
    });
	
    var kestlerForm = jQuery("#kestlerForm").validate({
        rules: {
            formName: {
                required: true
            },
            formStreet: {
                required: true
            },
            formPLZ: {
                required: true
            },
            formPhone: {
                required: true
            },
            formMail: {
                required: true,
                email: true
            }
        },
        submitHandler: function(form) {
            jQuery(form).ajaxSubmit({ target: "#formInfo" });
            kestlerForm.resetForm();
            setTimeout(function() {
                $('#formInfo').empty();
				$('.formCheck').val('nein');
				$('.checkBox').removeClass('checked');
				$('#formSubmit').prop('disabled', true);
            }, 5000)
        }
    })

    if ($('.bigSlider').length) {
        var bigSlider = new Swiper('.bigSlider.swiper-container', {
            spaceBetween: 0,
            autoplay: 5000,
            effect: 'fade',
            loop: true,
            loopAdditionalSlides: 1
        });
    }

    if ($('.refSlider').length) {
        var currentIndex = 0;
        var goToIndex = 0;
        var countSlides = $('.swiper-slide').length;
        var refSlider = new Swiper('.refSlider.swiper-container', {
            spaceBetween: 0,
            pagination: '.refSlider .swiper-pagination',
            nextButton: '.refSlider .swiper-button-next',
            prevButton: '.refSlider .swiper-button-prev',
            slidesPerView: 'auto',
            paginationClickable: true,
            loop: true,
        });

    }

    $('.checkBox').click(function() {
        var inputId = $(this).attr('data-check');
        if ($(this).hasClass('checked')) {
            $(this).removeClass('checked');
            $('#' + inputId).val('nein');
        } else {
            $(this).addClass('checked');
            $('#' + inputId).val('ja');
        }
    });

    $('#mobileButton').click(function(ev) {
        ev.preventDefault();
        if ($('body').hasClass('naviOpened')) {
            $('body').removeClass('naviOpened');
            $('#headerNavBox').css({ height: 0 });
        } else {
            $('body').addClass('naviOpened')
            var naviHeight = $('#headerNavBox > ul').height();
            $('#headerNavBox').css({ height: naviHeight });
        }
    });
});